footer {
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0), rgb(0, 0, 0));
  padding-block: 25vh 15vh;
  flex-direction: column;
}

.footer-icon-container {
  display: flex;
  justify-content: space-around;
  gap: 2em;
}

.footer-icon-container .icon {
  width: 6em;
  object-fit: fill;
}

.icon {
  filter: invert(100%) saturate(1000%) contrast(1000%);
  width: 100%;
}

.icon-link {
  color: #ffffff;
  text-decoration: none;
}

@media screen and (max-width: 900px) {
  footer {
    padding-block: 10vh 5vh;
  }

  .footer-icon-container {
    gap: 1em;
  }

  .footer-icon-container .icon {
    width: 3em;
    height: 3em;
  }
}