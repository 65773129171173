.bold-big {
  font-weight: 800;
  font-size: 1.85rem;
}

.microsoft-logo {
  height: 1rem;
  width: 1rem;
  margin-inline: 0.2rem;
}

.navbar {
  display: flex;
  justify-content: space-between;
  font-size: 1.5rem;
  color: var(--text-white);
  margin: 20px 30px;
}

.navbar > * {
  align-items: center;
}

.nav-left {
  display: flex;
  gap: 1rem;
  font-weight: bold;
}

.mobile-menu {
  display: none;
  z-index: 1;
}

.mobile-menu-header {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.mobile-drawer {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 80%;
  height: 100vh;
  background-color: #041a38;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 10px 0 rgba(11, 9, 34, 0.85);
  transform: translateX(-100%);
  transition: transform .5s ease-in-out;
}

.mobile-open-menu:hover + .mobile-drawer {
  transform: translateX(0);
}

.mobile-drawer .nav-link {
  padding-inline: 2rem;
}

.mobile-drawer > div {
  padding: 1rem 2rem;
}

.nav-right {
  display: flex;
  gap: 1em;
  justify-content: space-between;
}

.menu-container {
  position: relative;
}

.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}

.open-menu, .mobile-open-menu {
  cursor: pointer;
  font-size: 1.4em;
}

@media screen and (max-width: 900px) {
  .nav-right,
  .eka {
    display: none;
  }

  .mobile-menu {
    display: flex;
  }
}

.hidden {
  display: none;
}