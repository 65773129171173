.intro {
  margin-bottom: 20vh;
}

.date {
  font-size: 2em;
  font-family: JetBrains Mono, monospace;
}

.header-container {
  margin-bottom: 25%;
  min-height: 30vh;
  margin-left: 5%;
}

.itad-logo {
  width: 50%;
}

.microsoft-blocks {
  position: absolute;
  right: 0;
  bottom: -5vh;
  width: 50%;
  z-index: -1;
}

@media screen and (max-width: 1400px){
  .itad-logo {
    width: 70%;
  }
}

@media screen and (max-width: 900px) {
  .intro {
    margin-bottom: 5vh;
    min-height: 50vh;
  }

  .intro-header {
    font-size: 2.5em;
    margin-bottom: 0.5em;
  }

  .date {
    font-size: 1em;
  }

  .header-container {
    margin-bottom: 10vh;
  }

  .microsoft-blocks {
    width: 70%;
    bottom: 0;
  }
}

@media screen and (max-width: 400px) {
  .header-container {
    margin-bottom: 20vh;
    margin-inline: 3vh;
  }

  .itad-logo {
    width: 100%;
  }

  .microsoft-blocks {
    width: 100%;
    
  }
}