.design-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 100px 0;
}

.design {
  display: flex;
  align-items: center;
  justify-content: center;
}

.schedule-logo {
  width: 60%;
  height: auto;
  padding-block: 20px;
}

.timeline {
  width: 80%;
  font-size: 1.3rem;
  font-weight: bold;
  height: auto;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  & > div:nth-child(2n) * div {
    flex-direction: row-reverse;
  }
}

.schedule-element {
  display: flex;
  align-items: center;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  flex-direction: row;
  padding: 50px;
  max-height: 65vh;
  justify-content: space-between;
}

.schedule-time, .schedule-desc {
  color: #2B9DBC;
}

.schedule-topic {
  color: #E19A1B;
}

.timeline-content {
  border-radius: 5px;
  color: white;
  transition: 0.4s ease;
  overflow-wrap: break-word;
  border-radius: 6px;
  margin-left: 20px;
}

@media screen and (min-width: 768px) {
  .timeline {
    display: grid;
    grid-template-columns: 1fr 3px 1fr;
  }
  .timeline-middle {
    position: relative;
    width: 3px;
    background-color: white;
    height: 100%;
  }
  .main-middle {
    opacity: 0;
  }
  .timeline-circle {
    position: absolute;
    top: 0;
    left: 50%;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: white;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}
