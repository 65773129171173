.class-highlight {
  color: #c0ad60;
}

.string-highlight {
  color: rgba(253, 149, 90, 0.8);
}

#typewriter {
  font-size: 2em;
  margin: 0;
  font-family: JetBrains Mono, monospace;
  max-width: 100%;
  word-wrap: anywhere;
  white-space: pre-wrap;
  overflow-x: auto;
  z-index: -1;

  &:after {
    content: "|";
    animation: blink 500ms linear infinite alternate;
  }
}

@media screen and (max-width: 900px) {
  #typewriter {
    font-size: 1em;
  }
}

@-webkit-keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}