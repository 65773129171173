.about {
  padding-block: 5rem;

  & > div:nth-child(even) {
    background-color: var(--bg-color);
    color: var(--text-white);
  }

  & > div:nth-child(odd) {
    background-color: var(--bg-lighter-color);
    color: var(--text-white);
    flex-direction: row-reverse;
  }
}

.about-text-container {
  width: 80%;
  text-align: center;
  align-self: center;
}

.about-header {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.about-desc {
  font-size: 1.3rem;
  padding-inline: 4rem;
  padding-block: 2rem;
}

.about-container {
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  margin-block: 3rem;
  padding-block: 3rem;
  height: 80vh;

  img {
    width: 100%;
    margin: 1rem;
    height: auto;
    object-fit: contain;
  }
}

@media screen and (max-width: 1200px) {
  .about-container {
    height: 100vh;

    img {
      width: 50%;
    }
  }

  .about-header {
    font-size: 2.5rem;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 900px) {
  .about {
    padding: 0;
    flex-direction: column;

    & > div:nth-child(odd) {
      flex-direction: column;
    }

    img {
      width: 65%;
      margin: 1rem;
      height: auto;
      object-fit: contain;
    }
  }

  .about-container {
    flex-direction: column;
    height: auto;
    padding-block: 2rem;

    img {
      width: 90%;
      margin-inline: auto;
    }
  }

  .about-header {
    font-size: 2rem;
  }

  .about-desc {
    font-size: 1.3rem;
    padding: 0;
  }

  .about-text-container {
    width: 100%;
  }

  .about-desc {
    font-size: 1rem;
    padding-inline: 2rem;
  }
}

@media screen and (max-width: 600px) {
  .about-container {
    padding-block: 1rem;
  }
}
