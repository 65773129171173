:root {
  --bg-color: #090F2B;
  --bg-lighter-color: rgb(7, 12, 37);
  --text-white: #eeeeee;
  font-size: 16px;
  color: var(--text-white);
}

* {
  box-sizing: border-box;
}

body::-webkit-scrollbar {
  width: 1em;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

html {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  background-size: 224px;
  background: var(--bg-color);
}

body {
  margin: 0;
}

code {
  font-family:
    Menlo,
    Monaco,
    Lucida Console,
    Liberation Mono,
    DejaVu Sans Mono,
    Bitstream Vera Sans Mono,
    Courier New,
    monospace;
}

.featured-header {
  font-size: 4rem;
  letter-spacing: 1px;
  background-color: #0d0d18;
  padding: 5vh;
  margin: 0;
}

.nav-link {
  text-decoration: none;
  padding: 0.5em;
  border-radius: 5%;
  color: var(--text-white);
  transition: all 0.2s;
}

.nav-link:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.card {
  background: white;
  color: #222;
  border-radius: 8px;
  padding: 16px;
  margin: 16px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  width: 100%;
}

.icon-link {
  color: var(--text-white);
  text-decoration: none;
}

@media screen and (max-width: 900px) {
  :root {
    font-size: 20px;
  }

  .featured-header {
    font-size: 2rem;
  }
}

@media screen and (max-width: 375px) {
  :root {
    font-size: 16px;
  }
}